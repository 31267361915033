import React, { useState } from 'react'
import { HeadProps } from 'gatsby'
import styled from 'styled-components'
import { useInterval } from 'use-interval'
import { Layout, H1, H3, Button, HeadTags } from '@components'

const NotFoundPage = () => {
  const [rotate, setRotate] = useState(0)

  useInterval(() => setRotate(rotate + 1), 10)

  return (
    <Layout>
      <Content>
        <H1>404</H1>
        <StyledH3>Page not found</StyledH3>
        <Button href="/">Go back</Button>
        <CardWrapper {...{ rotate }}>
          <img src="/card-no-brand.svg" />
        </CardWrapper>
      </Content>
    </Layout>
  )
}

const Content = styled.div`
  position: relative;
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  perspective: 1000px;
`

const StyledH3 = styled(props => <H3 {...props} />)`
  margin-bottom: 0.5em;
`

interface ISvg {
  rotate: number
}

export const Head = (props: HeadProps) => HeadTags(props)

const CardWrapper = styled.div.attrs(({ rotate }: ISvg) => {
  return {
    style: {
      transform: `rotateZ(58deg) rotate3d(1, 0.63, 0, ${rotate}deg) scale(0.75)`,
    },
  }
})`
  position: absolute;
  left: 50%;
  top: 75%;
  background: #cf4eee;
  overflow: hidden;
  border-radius: 25px;
  will-change: transform;
  transform-origin: left top;
`

export default NotFoundPage
